import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';


import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { Theme, withStyles } from '@material-ui/core/styles';
import { ArrowForwardIos } from '@material-ui/icons';
import { manager } from './../../App';
import Drawer from './../elements/Drawer';
import ContactButton from './Maps/ContactButton';

declare let window: any;

const styles = (theme: Theme) => ({
        menuButton: {
            marginRight: 0,
        },
        title: {
            flexGrow: 1,
        },
        hide: {
            display: 'none'
        },
        appBar: {
            backgroundColor: 'transparent',
        },
        toolBar: {
            backgroundColor: 'var(--header-color)',
            flexDirection: "row-reverse",
            boxShadow: '0 20px 35px 11px var(--header-shadow-color)'
        }
    }) as any;


class Header extends Component<any, any> {
    state = {
        showDrawer: true
    }
    public static propTypes = {};

    componentWillUpdate() {
        if (window.$) window.$('.modal').modal();
    }

    componentDidUpdate() {
        if (window.M) window.M.AutoInit();
    }

    toggleDrawer = () => {
        this.setState({showDrawer: manager.show});
    }

    componentDidMount() {
        if (window.M) window.M.AutoInit();
        manager.toggleCallback = this.toggleDrawer;
    }

    render() {
        const { classes, context } = this.props;
        return (
            <div className={!context.showHeader ? classes.hide : 'header-container'}>
                <Drawer show={this.state.showDrawer} cxt={context}>
                    <IconButton className={classes.menuButton} id="hamburger_button" color="inherit" aria-label="Menu">
                        <ArrowForwardIos /> 
                    </IconButton>
                </Drawer>
                {<AppBar position="static" className={classes.appBar}>
                    <Toolbar className={classes.toolBar}>
                        {!context.user ? <>
                            <Button className="btn yellow-btn" variant="contained" component={props => <Link {...props} to={'/register'}/>}>Register</Button>
                            <Button className='btn' variant="contained" component={props => <Link {...props} to={'/login'}/>}>Login</Button>
                        </> : <>
                            <Button className="btn" variant="contained" component={props => <Link {...props} to={'/logout'}/>}>Logout</Button>
                            <Button className="btn" variant="contained" component={props => <Link {...props} to={'/profile'}/>}>Profile</Button>
                            {context.user.admin ? <Button className="btn yellow-btn" variant="contained" component={props => <Link {...props} to={'/admin'}/>}>Admin</Button> : ""}
                            
                        </>}
                        <ContactButton />
                        <a href={process.env.REACT_APP_GUILDS_PAGE} target="_blank"><Button className="btn" variant="contained" >Guilds</Button> </a>
                    </Toolbar>
                </AppBar>}
            </div>
        )
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withRouter(Header));