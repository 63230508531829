import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import React, { CSSProperties } from "react";
import api from "../../api/api";
import { IUserContext } from "../modules/Context";
import { genericButton } from "./GenericStyles";
const styless: {[key: string]: CSSProperties} = {
    redButton: {
        color: 'white',
        backgroundColor: 'red'
    },
}

interface IProps {
    cxt: IUserContext, 
}

interface IState {
    open: boolean,
}


export default class DeleteAccountButton extends React.Component<IProps, IState> {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    deleteAccount = () => {
        if (!this.props.cxt.user) {
            return;
        }
        if (this.props.cxt.user.id === 0) {
            return;
        }
        return api.user.delete(this.props.cxt.user.id, () => {
            this.props.cxt.logout();
        });
    }

    render() {
        return (
            <>
                <Button onClick={this.handleClickOpen} style={genericButton} >
                     Delete Account
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Are you sure you want to delete your account?</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.deleteAccount} color="primary" style={styless.redButton}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>)
    }
}