import React, { Fragment } from 'react';
import { BulkDeleteButton, Datagrid, List, Pagination, TextField } from 'react-admin';

const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50]} {...props}/>

const BulkActionButtons = (props : any) => (<Fragment>
	<BulkDeleteButton {...props}/>
</Fragment>);

const GuildList = (props: any) => (
	<List {...props}
		bulkActionButtons={<BulkActionButtons/>}
		pagination={<PostPagination/>}
	>
		<Datagrid rowClick='edit'>
			<TextField label='ID' source='id' />
			<TextField source='name' label='Name'/>
			<TextField source='description' label='Description'/>
			<TextField source='votes' label='Votes'/>
		</Datagrid>
	</List>
);
 
export default GuildList;
