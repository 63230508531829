import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import api from '../../api/api';

export default class FormDialog extends React.Component<{ markerId: number, callback: any }> {
    state = {
        open: false,
        description: ''
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = (e: any) => {
        const { state } = this;
        state.description = e.target.value;
        this.setState(state);
    }

    reportMarker = () => {
        api.markers.report(this.props.markerId, this.state.description, this.props.callback);
    }
    
    // This function should report pizza to api
    reportPizza = () => {
        
    }

    render() {
        return (
            <>
                <Tooltip title="Report this marker if you think it's inappropriate">
                    <div onClick={this.handleClickOpen} className="clickable report-marker">
                        Report marker
                    </div>
                </Tooltip>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Report marker</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please describe why do you want to report this marker
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label="Description"
                            name="description"
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.reportMarker} color="primary">
                            Report
                        </Button>
                    </DialogActions>
                </Dialog>
            </>)
    }
}