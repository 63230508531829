import { Button } from "@material-ui/core";
import React from "react";
import ContactModal from './Contact';

interface IState {
    open: boolean
}

export default class Contact extends React.Component<any, IState> {
    constructor(props: any){
        super(props);
        this.state = {
            open: false
        }
    }

    close = () => this.setState({open: false});

    open = () => this.setState({open: true});

    submit = () => {

    }

    render() {
        return <>
            <ContactModal
                open={this.state.open}
                close={this.close}
            />
            <Button variant="outlined"  className="btn" onClick={this.open} component="a" >
                Contact Us
            </Button>
        </>
    }
}