import { Button, SwipeableDrawer, Typography } from '@material-ui/core';
import { createStyles as makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { manager } from '../../App';
import config from './../../api/config';
import * as I from './../../api/interfaces';
import { IUserContext } from './../modules/Context';
import logo from './../styles/title.png';
import { CategoriesExtended } from './Drawer';

declare let window: any;


const styles = makeStyles({
    root: {
        width: '90%',
        maxWidth: '440px',
        alignSelf: 'center',
        padding: '15pt',
        boxShadow: 'none',
        border: 'none',
        background: 'none',
    },
    title: {
        marginBottom: '60px'
    },
    searchInput: {
        border: 'none',
        background: 'transparent',
        width: '100%',
        height: '100%',
        outline: 'none',
        paddingLeft: '40px',
        paddingRight: '40px',
        color: 'white',
        fontSize: '20px',
        fontFamily: 'Lato',
    },
    searchInputMobile: {
        border: 'none',
        background: 'transparent',
        width: 'calc(100% - 80px)',
        height: '92%',
        outline: 'none',
        paddingLeft: '40px',
        paddingRight: '40px',
        color: 'white',
        fontSize: '20px',
        fontFamily: 'Lato',
    },
    toggleLogin: {
        width: '45%',
        margin: 'auto',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px',
        cursor: 'pointer',
        color: 'white',
        fontFamily: 'Lato',
        textDecoration: 'none'
    },
    searchContainer: {
        width: '466px',
        height: '62px',
        borderRadius: '31px',
        backgroundColor: 'var(--background-semi-color)',
        marginTop: '26px',
        marginBottom: '45px',
        maxWidth: '90%'
    },
    toggleContainer: {
        height: '34px',
        borderRadius: '50px',
        backgroundColor: 'var(--inactive-color)',
        width: '449px',
        /*display: 'flex',*/
        maxWidth: '90%'
    },
    toggleContainerMobile: {
        height: '34px',
        borderRadius: '50px',
        backgroundColor: 'var(--inactive-color)',
        width: '466px',
        maxWidth: '90%'
    },
    input: {
        borderRadius: '14px'
    },
    toggle: {
        flex: 1,
        textAlign: 'center',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px',
        cursor: 'pointer',
        color: 'white',
        fontFamily: 'Poppins',
        fontWeight: 500,
        width:'50%',
        height:'100%'
    },
    drawer: {
        width: '29vw',
        maxWidth: '565px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '20px'
    },
    drawerMobile: {
        width: 565,
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '20px',
    }
})

interface IProps {
    list: CategoriesExtended[],
    cxt: IUserContext,
    allowed: number[],
    toggleCategory: (id: number) => void,
    onOpenStateChanged?: (open: boolean) => void,
    mobile?: boolean
}

interface IState {
    search: string,
    isOpen: boolean,
    allState: 'show'|'hide'
}

export default class SuperDrawer extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            search: '',
            isOpen: !this.props.mobile,
            allState: 'show'
        }
    }
    componentDidMount(){
        /*manager.onChange(() => {
            if(this.props.allowed.length === 0) {
                return this.setState({allState: 'hide'});
            } else if(this.props.allowed.length === manager.categories.length){
                return this.setState({allState: 'show'});
            }
        });*/
    }
    handleSearch = (ev: any) => {
        this.setState({ search: ev.target.value }, () => {
            manager.setSearch(this.state.search)
        });
    }

    toggleOff = () => {
        const { list, toggleCategory, allowed } = this.props;
        list.forEach(category => {
            if (!category.categories.length && allowed.includes(category.id)) toggleCategory(category.id);
            else category.categories.forEach(subcategory => {
                if (allowed.includes(subcategory.id)) toggleCategory(subcategory.id);
            });
        });
        this.setState({allState:'hide'})
    }
    toggleOn = () => {
        const { list, toggleCategory, allowed } = this.props;
        list.forEach(category => {
            if (!allowed.includes(category.id)) toggleCategory(category.id);
            category.categories.forEach(subcategory => {
                if (!allowed.includes(subcategory.id)) toggleCategory(subcategory.id);
            });
        });
        this.setState({allState:'show'})
    }

    toggle = (state?: boolean, isDesktop?: boolean) => () => {
        if (state !== undefined) {
            return this.setState({ isOpen: state }, () => {
                const handler = this.props.onOpenStateChanged;
                if (handler) {
                    handler(!state);
                }
            });
        }
        return this.setState({ isOpen: !this.state.isOpen }, () => {
            if(this.state.isOpen){
                document.body.classList.add("drawer-opened-desktop");
            } else {
                document.body.classList.remove("drawer-opened-desktop");
            }
            if(!manager.map || !manager.map.invalidateSize) return;
            let i = 0;
            const int = setInterval(() => {
                manager.map.invalidateSize();
                i++;
                if(i > 70){
                    clearInterval(int);
                }
            }, 8)
            const handler = this.props.onOpenStateChanged;
            if (handler) {
                handler(this.state.isOpen);
            }
        });
    }
    renderUserButtons = () => {
        const { mobile, cxt } = this.props;
        if(!mobile) return null;
        if(!cxt.user) return <div id="mobile-buttons-container">
            <Button className="btn yellow-btn" variant="contained" component={props => <Link {...props} to={'/register'} />}>Register</Button>
            <Button className='btn' variant="contained" component={props => <Link {...props} to={'/login'} />}>Login</Button>
        </div>
        return <div id="mobile-buttons-container">
            <Button className="btn" variant="contained" component={props => <Link {...props} to={'/logout'} />}>Logout</Button>
            <Button className="btn" variant="contained" component={props => <Link {...props} to={'/profile'} />}>Profile</Button>
            {cxt.user.admin ? <Button className="btn yellow-btn" variant="contained" component={props => <Link {...props} to={'/admin'} />}>Admin</Button> : ""}
        </div>
    }
    validateSubcategory = (sub: CategoriesExtended) => {
        if(!this.state.search) return true;
        const example = sub.markers.find(marker => marker.name.toLowerCase().includes(this.state.search.toLowerCase()));
        if(example) return true;
        return false;
    }
    validateCategory = (category: CategoriesExtended) => {
        if(!this.state.search) return true;
        if(category.categories.every(cat => !this.validateSubcategory(cat))) return false;
        return true;
    }
    getMarkersLength = (markers: I.ExtendedMarker[]) => {
        let marks = markers.filter(marker => marker.map === manager.activemap);
        if(!this.state.search) return marks.length;
        return marks.filter(marker => marker.name.toLowerCase().includes(this.state.search.toLowerCase())).length;
    }
    renderContent = () => {
        const isMobile = !!this.props.mobile;
        return <div
            style={isMobile ? styles.drawerMobile : styles.drawer}
            role="presentation"
        >
            <img src={logo} className='logo-login' />
            <Typography variant="h5" component="h1" className="interactive-map-title" style={styles.title}>
                {config.headerTitle}
                <div className="beta-container">Beta</div>
            </Typography>
            {this.renderUserButtons()}
            <div style={isMobile ? styles.toggleContainerMobile : styles.toggleContainer}>
                <div style={styles.toggle} className={this.state.allState === 'show' ? 'toggle-active' : ''} onClick={this.toggleOn}>SHOW ALL</div>
                <div style={styles.toggle} onClick={this.toggleOff} className={this.state.allState === 'hide' ? 'toggle-active' : ''}>HIDE ALL</div>
            </div>
            <div style={styles.searchContainer}>
                <input
                    type="text"
                    style={isMobile ? styles.searchInputMobile : styles.searchInput}
                    placeholder="Search for anything..."
                    onChange={this.handleSearch}
                    value={this.state.search}
                />
            </div>
            {this.props.list.filter(this.validateCategory).map(category => {
                //const db = category.categories.map(subcategory => subcategory.name.toLowerCase());
                //category.categories.map(subcategory => subcategory.name.toLowerCase())
                //if (!this.validateCategory) return null;
                return <div className="category-container" key={category.id} >
                    <h2
                        className={`category-title-container ${this.props.allowed.includes(category.id) ? 'active-cat' : 'non-active-cat'}`}
                        onClick={() => this.props.toggleCategory(category.id)}>{category.name}</h2>
                    <div className="category-subs-container">
                        {category.categories.filter(this.validateSubcategory).map(subcategory => {
                            return <div
                                key={subcategory.id}
                                className={`subcategory-container ${this.props.allowed.includes(subcategory.id) ? 'active-cat' : 'non-active-cat'}`}
                                onClick={() => this.props.toggleCategory(subcategory.id)}
                            >
                                <div className="subcategory-icon"><img src={`${config.apiAddress}icon/${subcategory.id}`} /></div>
                                <h2 className="subcategory-title-container">{subcategory.name}</h2>
                                <div className="subcategory-count">{this.getMarkersLength(subcategory.markers)}</div>
                            </div>
                        })}
                    </div>
                </div>
            })}
            <a href="https://diablo4guild.com/faq" className='stopencja' style={{color: '#DCDCDC' }}>FAQ</a>
            <a href="https://diablo4guild.com/how-to-promote-your-diablo-4-guild" className='stopencja' style={{color: '#DCDCDC' }}>How to Promote Your Guild</a>
            <a href="https://diablo4guild.com/how-to-level-in-diablo-4" className='stopencja' style={{color: '#DCDCDC' }}>How to level in Diablo 4</a>
            <div className="stopencja">
            This website is not endorsed by, directly affiliated with, maintained, authorized, or sponsored by Blizzard Entertainment, Inc.. Diablo is a trademark or registered trademark of Blizzard Entertainment, Inc., in the U.S. and/or other countries. All product and company names are the registered trademarks of their original owners. The use of any trade name or trademark is for identification and reference purposes only and does not imply any association with the trademark holder of their product brand.
            </div>
            <a href="/PrivacyPolicy.pdf" className='stopencja'>Privacy Policy</a>
            <a href="/TermsandCondition.pdf" className='stopencja'>Terms & Conditions</a>
        </div>
    }
    render() {
        let children: any = this.props.children;
        if (children) {
            if(this.props.mobile){
                children = React.cloneElement(children, { onClick: this.toggle(true) })
            } else {
                children = React.cloneElement(children, { onClick: this.toggle(undefined, true) })
            }
        }
        if(this.props.mobile){
            return <>
                <SwipeableDrawer
                open={this.state.isOpen}
                onOpen={this.toggle(true)}
                onClose={this.toggle(false)}
                id="mobile_drawer"
                PaperProps={{
                    classes: {
                        root: 'drawer-dyngs-mobile'
                    }
                }}
                >
                    {this.renderContent()}
                </SwipeableDrawer>
                {children}
            </>
        }
        return <><SwipeableDrawer
            open={this.state.isOpen}
            onOpen={this.toggle(true)}
            onClose={this.toggle(false)}
            variant="persistent"
            id="desktop_drawer"
            PaperProps={{
                classes: {
                    root: 'drawer-dyngs'
                }
            }}
        >
            {this.renderContent()}
        </SwipeableDrawer>{children}</>
    }
}