import { IconButton, Snackbar, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip as LeafTooltip, Marker, Popup } from 'react-leaflet';
import AcceptButton from '../../elements/AcceptButton';
import Alert from '../../elements/Alert';
import DeleteMarkerButton from '../../elements/DeleteMarkerButton';
import EditMarkerModal from '../../elements/EditMarkerModal';
import ReportButton from '../../elements/ReportButton';
import ToggleFoundButton from '../../elements/ToggleFoundButton';
import VoteButton from '../../elements/VoteButton';
import * as I from './../../../api/interfaces';
import Edit from './../../styles/edit_button.png';
import * as Icons from './../../styles/markers/leaflet-color-markers';
import { IUserContext } from './../Context';

interface IProps {
    onDrag: (id: number) => any,
    draggable: boolean,
    marker: I.ExtendedMarker,
    cxt: IUserContext,
    toggleDrag: (id: number, state?: boolean) => any
    openPopup: (id: number) => any
    loadMarkers: () => void,
    categories: I.Category[],
    color: string | null,
    onUpdate: any
}

export type EditingType = 'editing' | 'suggestion'  | 'delete' | null;

interface IState {
    editing: EditingType;
    modals: {
        editing: boolean;
        suggestion: boolean;
        delete: boolean;
    },
    dragging: boolean,
    showTooltip: boolean,
    clipboard: boolean,
}

export default class Markers extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            editing: null,
            modals: {
                editing: false,
                suggestion: false,
                delete: false
            },
            dragging: false,
            showTooltip: false,
            clipboard: false
        }
    }
    onOpenModal = (type: 'editing' | 'suggestion' | 'delete') => () => {
        this.setState({ showTooltip: false });
        if (this.isDragging) return;
        this.setState(state => {
            state.modals[type] = true;
            return state;
        });
    }
    onCopy = (text: string, success:boolean) => {
        if(success){
            this.setState({clipboard: true});
        }
    }
    closeClipboard = () => {
        this.setState({clipboard: false})
    }
    onCloseModal = (type: 'editing' | 'suggestion' | 'delete') => () => {
        this.setState(state => {
            state.modals[type] = false;
            return state;
        });
    }
    toggleModal = (type: 'editing' | 'suggestion' | 'delete') => {
        //this.setState({showTooltip: false});
        if (this.state.modals[type]) return this.onCloseModal(type);
        return this.onOpenModal(type);
    }
    toggleEditing = (editing: EditingType) => () => {
        this.props.toggleDrag(this.props.marker.id, !!editing);
        this.setState({ editing, showTooltip: !!editing });
    }
    toggleDelete = () => () => {
    }

    isDragging: boolean = false;

    handleDragStart = () => {
        this.isDragging = true;

        //this.setState({dragging:true})
    }
    handleDragEnd = (e: any) => {
        setTimeout(() => {
            this.isDragging = false;
        }, 50);
        this.props.onDrag(this.props.marker.id)(e);
    }
    onMouseClick = (e: any) => {
    }
    render() {
        const { draggable, cxt, marker, toggleDrag, loadMarkers, openPopup, categories } = this.props;
        const { user } = cxt;
        const mapn = 'sanctuary';
        return (<>
            <Marker
                icon={Icons.customIcon(marker, marker.accepted)}
                position={marker.position}
                draggable={draggable}
                onDragend={this.handleDragEnd}
                onDragstart={this.handleDragStart}
                ref={openPopup(marker.id)}
                //onmousedown={this.onMouseClick}
                onclick={this.state.editing ? this.toggleModal(this.state.editing) : undefined}
                show={marker.id === 1}
            >
                {this.state.showTooltip && !this.isDragging ? <LeafTooltip direction="bottom" permanent>Move your marker and click it to edit</LeafTooltip> : null}
                <Popup className={this.state.editing ? 'hide-mf-popup' : undefined}>
                    <div className="popup-content-container">
                        <div className="popup-marker-data">
                            <strong>
                                <h3 className="marker-title">{marker.name}</h3>
                                <div className="points-container">{marker.all_votes} points</div>
                                <div className="action-container">
                                    <ToggleFoundButton
                                        onUpdate={this.props.onUpdate}
                                        callback={() => {loadMarkers(); this.props.onUpdate()}}
                                        isFound={marker.found}
                                        markerId={user ? marker.id : 0}
                                    />
                                    {user && (user.admin || (user.id === marker.owner && marker.private)) ?
                                        <Tooltip title={'Edit this marker'} aria-label={'Edit this marker'}>
                                            <IconButton style={{ padding: '8px' }}>
                                                <img src={Edit} onClick={this.toggleEditing('editing')} />
                                            </IconButton>
                                        </Tooltip>

                                        : null}
                                    {user && !marker.private ? <VoteButton status={marker.vote} markerId={marker.id} callback={loadMarkers} /> : null}
                                    { 
                                        user && marker.id !== 0 && this.props.marker.private && user.id === this.props.marker.owner ? 
                                            <DeleteMarkerButton marker={marker} callback={loadMarkers}/>
                                            : null 
                                    }
                                </div>
                            </strong>
                            <div className="authors">
                                {marker.author ? <div><strong>Author:</strong> {marker.author.username}</div> : null}
                                {marker.lastEditedBy ? <div><strong>Last edited by:</strong> {marker.lastEditedBy.username}</div> : null}
                            </div>
                            <div className="marker-desc">{marker.description || ''}</div>
                            <Tooltip title="Copy marker's URL to clipboard">
                                <CopyToClipboard text={`${window.location.origin}/${mapn}/${marker.name.replace(/ /g, '-').replace(/[^a-zA-Z0-9-]/g, '')}/${marker.id}`} onCopy={this.onCopy}>
                                    <div><div className="clickable copy-marker">Copy marker to clipboard</div></div>
                                </CopyToClipboard>
                            </Tooltip>
                            {this.props.cxt.user?.admin && !marker.private && !marker.accepted
                                ? <AcceptButton
                                        markerId={marker.id}
                                        callback={loadMarkers}  
                                        marker={marker}  
                                        />                      
                            : ""}

                            <div className="marker-options-container">
                                {user && !marker.private ?
                                    <Tooltip title="Suggest changes for this marker if you think it contains errors">
                                        <div onClick={this.toggleEditing('suggestion')} className="clickable suggestion-marker">Make suggestion</div>
                                    </Tooltip> : null}
                                {user && !marker.private ? !marker.reported ?
                                    <ReportButton
                                        markerId={marker.id}
                                        callback={loadMarkers}
                                    />
                                    : <div>You already reported this marker</div> : null}
                            </div>
                        </div>

                    </div>
                </Popup>
            </Marker>
            <Snackbar
                autoHideDuration={4000}
                open={this.state.clipboard}
                onClose={this.closeClipboard}
            >
                <Alert type="success">
                    Marker's address has been copied to clipboard!
		        </Alert>
            </Snackbar>
            <EditMarkerModal
                categories={categories}
                toggleDrag={toggleDrag}
                marker={marker}
                submit={loadMarkers}
                toggleEditing={this.toggleEditing}
                open={this.state.modals.editing}
                onOpen={this.onOpenModal('editing')}
                onClose={this.onCloseModal('editing')}
            />
            <EditMarkerModal
                categories={categories}
                suggestion
                toggleDrag={toggleDrag}
                marker={marker}
                submit={loadMarkers}
                toggleEditing={this.toggleEditing}
                open={this.state.modals.suggestion}
                onOpen={this.onOpenModal('suggestion')}
                onClose={this.onCloseModal('suggestion')}
            />
        </>
        )
    }
}