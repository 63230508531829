import React from 'react';
import { BooleanField, DeleteButton, Edit, SimpleForm, TextField, Toolbar } from 'react-admin';
import ApproveButton from './ApproveButton';
const PostCreateToolbar = (props: any) => (
    <Toolbar {...props} >
		<ApproveButton />
		<DeleteButton/>
    </Toolbar>
);

export const SuggestionEdit = (props: any) => (
	<Edit {...props}>
		<SimpleForm toolbar={<PostCreateToolbar/>}>
			<TextField disabled label='ID' source='id' />
			<TextField source='name' required label='Suggested name'/>
			<TextField source='markerEnt.name' required label='Original name'/>
			<TextField source='description' required label='Suggested description'/>
			<TextField source='markerEnt.description' required label='Suggested description'/>
			<TextField source='categoryEnt.name' required label='Suggested category'/>
			<TextField source='markerEnt.categoryEnt.name' required label='Original category'/>
			<TextField source='owner.username' required label="Suggestion's author"/>
			{/* <BooleanField source='isDLC' label="DLC" /> */}
			<BooleanField source='private' label="Is private?" />
		</SimpleForm>
	</Edit>
)
