import React from "react";
import "./index.scss";
export const PrivacyPolicy = () => {
  return (
    <main id="privacy-policy">
      <h1>Privacy Policy</h1>
      <ol>
        <li>
          We invite you to read the following document on the subject of privacy
          protection and the method of data processing by Michał Majka
          Neurogrine, ul.&nbsp;Armii&nbsp;Krajowej&nbsp;7/14, 30-150 Krakow
          (hereinafter referred to as "We" via the Website available at the URL:
          diablo4map.com.
        </li>
        <li>Privacy Policy applies from 30.05.2023.</li>
        <li>
          Privacy Policy complements the Terms and Conditions available at URL:
          diablo4map.com/TermsandCondition.pdf.
        </li>
        <li>
          The concepts used in the Privacy Policy should be interpreted in
          accordance with the Terms and Condition, unless their meaning is
          explained differently in this document.
        </li>
      </ol>
      <div className="pp-header">
        I. IINFORMATION ABOUT PROCESSING OF PERSONAL DATA
      </div>
      <p>
        Personal data are processed in accordance with Regulation (EU) 2016/679
        of the European Parliament and of the Council of 27 April 2016 on the
        protection of individuals with regard to the processing of personal data
        and on the free movement of such data and repealing Directive 95/46 / EC
        ( general data protection regulation - hereinafter referred to as
        "GDPR") and provisions on the protection of personal data applicable in
        the territory of the Republic of Poland (eg the Act on the protection of
        personal data).
      </p>
      <p>
        Every data subject has the right of access to data, rectification,
        deletion or limitation of processing, the right to object, the right to
        lodge a complaint to the supervisory body. All inquiries about the
        method of data processing should be sent to the following e-mail
        address: michael@lexogrine.com.
      </p>
      <p>
        Service Provider reserves the right to share the User's personal data
        and other data with entities authorized under the applicable law (eg law
        enforcement authorities). Service Provider does not share personal data
        with other entities than authorized on the basis of applicable law.
      </p>
      <p>
        Service Provider may entrust to process personal data stored in the ICT
        system of the processor having its registered office in the European
        Economic Area and fulfilling the requirements provided for by the law in
        force in the European Union.
      </p>
      <p>
        Data that are not personal data but contain data about the method of use
        are processed by the Service Provider without time and territorial
        restrictions and may be made available to third parties cooperating with
        the Service Provider.
      </p>
      <table>
        <thead>
          <tr>
            <th>Legal basis for processing</th>
            <th>The purpose of processing</th>
            <th>The period of data processing</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>art. 6.1.a of GDPR</td>
            <td>
              The data subject has agreed to the processing of his personal data
              for the given purpose (eg consent to send a newsletter).
            </td>
            <td>
              The data is processed until the consent is withdrawn, but after
              the consent for processing has been withdrawn, the information
              that the consent has been granted and which shares were made on
              its basis by Us, and when it was withdrawn and who is affected are
              not subject to the deletion until the time of the claims in
              accordance with legal regulations (it is necessary for the purpose
              of establishing, investigating or defending legal claims).
            </td>
          </tr>
          <tr>
            <td>art. 6.1.b of GDPR</td>
            <td>The processing is
necessary for the
performance of the
Agreement to which the
data subject is a party, or
to take action at the
request of the data
subject, before
concluding the
Agreement.</td>
            <td>The data is processed until
the Account is deleted,
but after deletion of the
Account, the data on who,
when and how they used
the Account are archived
for the purpose of
establishing, investigating
or defending legal claims.</td>
          </tr>
          <tr>
            <td>art. 6.1.c of GDPR</td>
            <td>Processing is necessary to
fulfill the legal obligation
incumbent on us.</td>
            <td>Payment details and
accounting documents
are archived for the period
required by law</td>
          </tr>
          <tr>
            <td rowSpan={4}>art. 6.1.f of GDPR</td>
            <td>Handling of inquiries
related to the provision of
services.</td>
            <td>The data is processed for
the duration of the inquiry
and is archived for the
purpose of establishing,
investigating or defending
legal claims.</td>
          </tr>
          <tr>
            <td>Complaints service. </td>
            <td>The data is processed for
the time the complaints
are processed and they
are archived for the
purpose of establishing,
investigating or defending
legal claims.
</td>
          </tr>
          <tr>
            <td>Making statistics based on
the Users' activity.</td>
            <td>The data is processed for
the period of using the
Account by the User.
</td>
          </tr>
          <tr>
            <td>Storing documentation
for the purpose of establishing, investigating
or settling claims.</td>
            <td>Data can not be deleted
until the claims have expired in accordance
with the law.</td>
          </tr>
        </tbody>
      </table>
      <div className="pp-header">
        II. NOTIFICATIONS / NEWSLETTER
      </div>
      <p>
        The User may manage notifications displayed on the Platform. By default,
        notifications are turned off. We can send by the Platform notifications
        (so-called pushe) if it is marked in the Account.
      </p>
      <p>
        The User can agree to receive the newsletter on the e-mail address
        provided by him. Newsletter is sent 1 in a week. The user can
        unsubscribe from the newsletter at any time by canceling the
        subscription.
      </p>
      <div className="pp-header">
        III. COOKIES
      </div>
      <p>
        Cookies are transferred to web browsers and then they are stored in the
        device's memory and read by the server each time you connect to the
        site.Cookies do not contain any information enabling third parties to
        know your personal data or contact you via, for example, electronic mail
        or telephone. Please also note that cookies do not allow us or any
        unauthorized persons access to your private device.
      </p>
      <div className="pp-header">
        What kind of cookies are we processing?
      </div>
      <p>
        <div className="cookie">Technical cookies</div>
        They enable proper use of message transmission and remembering your
        settings when you check this option on the website and / or allow us to
        create simple statistics on the use of the Website. We use cookies that
        are responsible for the login session and the chosen language.
      </p>
      <p>
        <div className="cookie">PHPSESSID</div>
        Expiration date: at the end of the session, i.e. after all browser
        windows are closed Description: Stores information that allows to
        distinguish the user currently using the site to ensure correct
        navigation through its contents.
      </p>
      <p>
        <div className="cookie">Google Analytics</div>
        A web analytics tool that allows us to create simple statics and observe
        what tabs are clicked on the Website, where the traffic is directed to,
        etc. Detailed information can be found at:
        https://policies.google.com/privacy?hl=en
      </p>
      <div className="pp-header">
        How can I disable cookies?
      </div>
      <p>
        By using your browser's settings, you decide to process cookies. If you
        do not want cookies to be processed, you can disable cookies (to do
        this, select the appropriate privacy options in your browser). You can
        delete cookies from your browser at any time.
      </p>
      <p>
        Disabling cookies in a web browser may affect how the content of the
        Platform appears and, for example, lead to logging out or not
        maintaining the login session.
      </p>
    </main>
  );
};
