import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import API from './api/api';
import Content from './components/modules/Content';
import Footer from './components/modules/Footer';
import Header from './components/modules/Header';
import ScrollToTop from './components/modules/Scroll';

import { IUserContext, UserType as UT, UserContext, defaultContext } from './components/modules/Context';
import { IUser } from './components/modules/definitions';

import MarkerFilter from './components/modules/ModsFilter';
import './components/styles/index.css';
import './components/styles/maincolors.css';

export const manager = new MarkerFilter();
//import './components/styles/materialize.min.css';
//import './components/styles/index.css';

declare let window: any;

function inIframe () {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}
class App extends React.Component<any,{user:IUserContext}> {
	constructor(props: any){
		super(props);
		const AppContext = this;

		defaultContext.logout = function() {
			API.user.logout(() => {
				this.type = UT.Guest;
				this.user = undefined;
				AppContext.setState({user: defaultContext});
			});
		};

		defaultContext.loadUser = function(){
			API.user.getMyself((user: IUser) => {
				this.type = user.admin ? UT.Admin : UT.User;
				this.user = user;
			
				AppContext.setState({user: defaultContext});
			}, () => {
				this.type = UT.Guest;
				this.user = undefined;
				AppContext.setState({user: defaultContext});
			});
		}

		defaultContext.login = function(username:string, password: string, errorCallback?:any) {
			API.user.login({username, password}, ()=>{
				this.loadUser();
			}, errorCallback);
		}
		defaultContext.setHeader = function(show: boolean){
			this.showHeader = show;
			AppContext.setState({user: defaultContext});
		}

		defaultContext.updateContext = defaultContext.loadUser;

		defaultContext.setTOSAccepted = function() {
			if (!this.user) {
				return;
			}
			this.user.hasAcceptedTOS = true;
			AppContext.setState({user: defaultContext});
		}

		this.state = {
			user: defaultContext,
		}
	}

	componentDidMount(){
		this.state.user.loadUser();
		if(inIframe()){
			document.body.classList.add("iframe-detected")
		}
	}

	render() {
		if(inIframe()){
			return null;
		}

		return (
			//<AdminPanel />
			<BrowserRouter>
				<ScrollToTop>
					<UserContext.Provider value={this.state.user}>
						{<Header context={this.state.user}/>}
						<Content />
						<Footer />
					</UserContext.Provider>
				</ScrollToTop>
			</BrowserRouter>
		);
	}
}

export default App;
