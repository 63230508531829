import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import ISO6391 from 'iso-639-1';
import React from 'react';
import {
  DeleteButton,
  Edit,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  showNotification
} from 'react-admin';
import { connect } from 'react-redux';
import api from '../../../../api/api';

const GuildMembersList = ({ record }: any) => {
	const memberships = record.memberships;

	if (!memberships?.length) {
		return <span></span>;
	}
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				margin: '20px 0',
				fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
			}}
		>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>ID</TableCell>
						<TableCell>Name</TableCell>
						<TableCell>Joined at</TableCell>
						<TableCell>Account created at</TableCell>
						<TableCell>Role</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{memberships.map((entry: any) => {
						return (
							<TableRow key={entry?.userId}>
								<TableCell>
									{entry?.userId ? (
										<a href={`/admin#/users/${entry?.userId}`}>{entry?.userId}</a>
									) : (
										<div>No user found</div>
									)}{' '}
								</TableCell>
								<TableCell>{entry?.username}</TableCell>
								<TableCell>{entry?.joinedAt}</TableCell>
								<TableCell>{entry?.accountCreatedAt}</TableCell>
								<TableCell>{entry?.role}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</div>
	);
};

const getLanguages = () => {
	const languages = ISO6391.getAllNames();
	return languages.map(language => ({
		id: language,
		name: language
	}));
};

const AVATAR_MAX_SIZE = 2 * 4 / 3 * 1024 * 1024; // 2MB

const GuildEditToolbar = connect(undefined, { showNotification })((props: any) => {
	const { showNotification } = props;
	return (
		<Toolbar {...props}>
			<SaveButton />
			<Button
				style={{ backgroundColor: '#2196f3', color: 'white', margin: '5px' }}
				onClick={() => {
					api.guilds.update(
						props.record.id,
						{
							name: props.record.name,
							icon: null,
							language: props.record.language,
							tag: props.record.tag,
							platforms: props.record.platforms
						},
						() => {
							showNotification(`Icon removed!`, 'success');
							window.location.reload();
						},
						() => {
							showNotification(`Unknown error encountered!`, 'error');
						}
					);
				}}
			>
				Remove Icon
			</Button>
			<Button
				style={{ backgroundColor: '#2196f3', color: 'white', margin: '5px' }}
				onClick={() => {
					const input = document.createElement('input');
					input.type = 'file';
					input.accept = 'image/png';
					input.onchange = (e: any) => {
						const file = e.target.files[0];
						const reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = () => {
							if (!reader.result) {
								return;
							}
							if (
								(typeof reader.result === 'string' && reader.result.length > AVATAR_MAX_SIZE) ||
								(typeof reader.result === 'object' && reader.result.byteLength > AVATAR_MAX_SIZE)
							) {
								showNotification(`Image too large!`, 'error');
								return;
							}
							api.guilds.update(
								props.record.id,
								{
									name: props.record.name,
									icon: reader.result.toString(),
									language: props.record.language,
									tag: props.record.tag,
									platforms: props.record.platforms
								},
								() => {
									showNotification(`New icon uploaded!`, 'success');
									window.location.reload();
								},
								() => {
									showNotification(`Unknown error encountered!`, 'error');
								}
							);
						};
					};
					input.click();
				}}
			>
				Upload Icon
			</Button>
			<DeleteButton style={{ marginLeft: 'auto', float: 'right' }} />
		</Toolbar>
	);
});

export const GuildEdit = (props: any) => {
	return (
		<Edit {...props}>
			<SimpleForm toolbar={<GuildEditToolbar/>}>
				<img
					src={`${process.env.REACT_APP_BACKEND_REALM}/guilds/${props.id}/icon`}
					alt="Guild Icon"
					style={{ width: 'auto', height: 'auto', maxHeight: '200px', maxWidth: '200px' }}
				/>
				<TextInput disabled label="ID" source="id" />
				<TextInput source="name" label="Name" />
				<TextInput source="tag" label="Tag" />
				<TextInput source="description" label="Description" />
				<TextInput source="requirements" label="Requirements" />
				<SelectInput source="language" label="Language" choices={getLanguages()} />
				<TextInput disabled source="platforms" label="Platforms" />
				<TextInput disabled source="votes" label="Votes" />
				<TextInput disabled source="memberCount" label="Member Count" />
				<TextInput source="discordLink" label="Discord Link" />
				<GuildMembersList />
			</SimpleForm>
		</Edit>
	);
};
