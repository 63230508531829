import { Paper, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { manager } from '../../../App';
import config from '../../../api/config';
import logo from './../../styles/title.png';
const styles: {[name: string]: CSSProperties} = {
    root: {
        width: '90%',
        maxWidth: '440px',
        alignSelf: 'center',
        padding: '15pt',
        boxShadow: 'none',
        border: 'none',
        background: 'none'
    },
    errorText: {
        border: 'none',
        fontSize: '60pt',
        color: 'white',
        textAlign: 'center',
    },
    descriptionText: {
        border: 'none',
        fontSize: '36pt',
        color: 'white',
        textAlign: 'center',
    },
    alreadyHave:{
        fontSize:'14px',
        fontFamily:'Lato',
        color: 'white',
        fontWeight:300,
        textAlign: "center" as "center",
        textTransform: 'uppercase' as "uppercase",
        letterSpacing: '0.5px',
        marginBottom: '70px'
    },
    input: {
        borderRadius: '14px'
    }
}


export default class ErrorPage extends React.Component<{ error: string, description: string }> {
    constructor(props: any) {
        super(props);
    }
    public static propTypes = {};

    componentDidMount() {
        manager.toggle(false);
    }

    render() {
        return (
            <Paper style={styles.root}>
                <Link to='/' className="logo-container"><img src={logo} className='logo-login'/></Link>
                <Typography variant="h5" component="h1" className="interactive-map-title avatar-site not-low">
                {config.headerTitle}
                <div className="beta-container">Beta</div>
                </Typography>
                <Typography variant="h1" component="h1" style={styles.errorText}>
                    {this.props.error}
                </Typography>
                <Typography variant="h3" component="h1" style={styles.descriptionText}>
                    {this.props.description}
                </Typography>
                <div style={styles.alreadyHave}>
                    <Link to='/' className="login-link">Go back</Link>
                </div>
            </Paper>
        )
    }
}
