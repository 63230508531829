import React from 'react';
import { BooleanInput, Edit, SimpleForm, TextInput } from 'react-admin';

/*export const ReportCreate = (props: any) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source='name'/>
			<TextInput source='description'/>
		</SimpleForm>
	</Create>
)*/

export const ReportEdit = (props: any) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput disabled label='ID' source='id' />
			<TextInput disabled source='description'/>
			<TextInput disabled source='owner.username' label='Username'/>
			<TextInput disabled source='markerEnt.name' label='Marker Name'/>
			<TextInput disabled source='markerEnt.id' label='Marker ID'/>
			<BooleanInput source='open' />
		</SimpleForm>
	</Edit>
)
