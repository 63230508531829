import { Button } from '@material-ui/core';
import React from 'react';
import {
	BooleanInput,
	Create,
	DeleteButton,
	Edit,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	showNotification
} from 'react-admin';
import { connect } from 'react-redux';
import api from '../../../../api/api';

export const UserCreate = (props: any) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source="username" />
			<TextInput source="description" />
			<TextInput source="password" />
			<TextInput source="steamid" />
			<TextInput source="facebookid" />
			<TextInput source="googleid" />
			<TextInput source="battlenetid" />
			<TextInput source="email" />
			<BooleanInput source="admin" />
		</SimpleForm>
	</Create>
);

const AVATAR_MAX_SIZE = 2 * 4 / 3 * 1024 * 1024; // 2MB

const UserEditToolbar = connect(undefined, { showNotification })((props: any) => {
	const { showNotification } = props;
	return (
		<Toolbar {...props}>
			<SaveButton />
			{props.record && props.record.hasAvatar ? (
				<Button
					style={{ backgroundColor: '#2196f3', color: 'white', margin: '5px' }}
					onClick={() => {
						api.user.update(
							props.record.id,
							{ avatar: null },
							() => {
								showNotification(`Avatar removed!`, 'success');
								window.location.reload();
							},
							() => {
								showNotification(`Unknown error encountered!`, 'error');
							}
						);
					}}
				>
					Remove Avatar
				</Button>
			) : null}
			<Button
				style={{ backgroundColor: '#2196f3', color: 'white', margin: '5px' }}
				onClick={() => {
					const input = document.createElement('input');
					input.type = 'file';
					input.accept = 'image/png';
					input.onchange = (e: any) => {
						const file = e.target.files[0];
						const reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = () => {
							if (!reader.result) {
								return;
							}
							if (
								(typeof reader.result === 'string' && reader.result.length > AVATAR_MAX_SIZE) ||
								(typeof reader.result === 'object' && reader.result.byteLength > AVATAR_MAX_SIZE)
							) {
								showNotification(`Image too large!`, 'error');
								return;
							}
							api.user.update(
								props.record.id,
								{ avatar: reader.result },
								() => {
									showNotification(`New avatar uploaded!`, 'success');
									window.location.reload();
								},
								() => {
									showNotification(`Unknown error encountered!`, 'error');
								}
							);
						};
					};
					input.click();
				}}
			>
				Upload Avatar
			</Button>
			<DeleteButton style={{ marginLeft: 'auto', float: 'right' }} />
		</Toolbar>
	);
});

export const UserEdit = ((props: any) => 
	<Edit {...props}>
		<SimpleForm toolbar={<UserEditToolbar/>}>
			<img
				src={`${process.env.REACT_APP_BACKEND_REALM}/avatar/${props.id}`}
				alt={'User Image'}
				style={{ width: 'auto', height: 'auto', maxHeight: '200px', maxWidth: '200px' }}
			/>
			<TextInput disabled label="ID" source="id" />
			<TextInput source="username" />
			<TextInput source="description" />
			<TextInput source="steamid" />
			<TextInput source="facebookid" />
			<TextInput source="googleid" />
			<TextInput source="battlenetid" />
			<TextInput source="email" />
			<BooleanInput source="admin" />
			<BooleanInput source="banned" />
			<BooleanInput source="deleted" />
		</SimpleForm>
	</Edit>
);
